import React from "react";
import { useState, useEffect} from 'react';
import { useRef } from 'react';
import apiPath from "../../global/api";


function ServiceDropDown(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([{ label: "Loading ...", value: "" }]);
    const [value, setValue] = useState();

    

    var uslugeInputRef= useRef();
    uslugeInputRef = props.reference;

    useEffect(() => {
        setIsLoading(true);
  fetch(
          apiPath("usluge"),{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
    
        }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const usluge = [];
    
            for (const key in data) {
              const usluga = {
                id: key,
                ...data[key]
              };
              console.log(usluga);
              usluge.push(usluga);
            }
          
          
            setItems(usluge.map((usluga) => ({ label: usluga.naziv , value: usluga.naziv})));
            
            
            setIsLoading(false);
          
        });
  
  
      }, []);

      
       return(
        <select disabled={isLoading} ref={uslugeInputRef}
        value={value}
      onChange={e => setValue(e.currentTarget.value)} required >
      {items.map(item => (
        <option
          key={item.value}
          value={item.value}
        >
          {item.label}
        </option>
      ))}
    </select>
  );
      }    

     
      export default ServiceDropDown;
   