
import classes from "./servicesList.module.css";


function PatientServicesList(props) {
  return (
    <ul className={classes.list}>
      {props.services.map((service) => (
        <li key={service.id}> - {service.usluga}</li>
      ))}
    </ul>
  );
}

export default PatientServicesList;