import RasporedTermina from "./RasporedTermina";
import classes from "./servicesList.module.css";


function ListaRasporeda(props) {
  return (
    <ul className={classes.list}>
      {props.termini.map((termin) => (
        <RasporedTermina
          key={termin.termin_id}
          id={termin.termin_id}
          usluga= {termin.usluga_id}
          pocetak_termina= {termin.pocetak_termina}
          kraj_termina = {termin.kraj_termina}
          ime_djelatnik={termin.ime_djelatnik}
          prezime_djelatnik={termin.prezime_djelatnik}


        />
      ))}
    </ul>
  );
}

export default ListaRasporeda;