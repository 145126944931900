import ShowSuccessAdded from "../components/general/ShowSuccessAdded";

function AddSuccessPage(props) {
    return (
      
        < ShowSuccessAdded message = {props.message} id = {props.id}/>
     
    );
  }
  
export default AddSuccessPage;