import React from "react";
import classes from "./servicesList.module.css";


function EvidencijaB(props){
    return(
        <li>
          <div className={classes.wrapper} >
          <div className={classes.section}>
            <div className={classes.evidTitle}>
            <h3>{props.firstname} {props.lastname} </h3>
            </div>
            <div>
            <p><b>Dan: </b> {props.dan}</p>
            </div>
            <div>
            <p><b>Id doktora:</b> {props.doktor_id}</p>
            </div>
            
           
            
            
          
          </div>
          </div>
        </li>
    );
}

export default EvidencijaB;