
import classes from './Layout.module.css';
import{useContext} from 'react';
import UserContext from "../../store/user-context";

function Greeting() {

  const userCtx = useContext(UserContext);

  function getRole(){
    if(userCtx.role === "ROLE_ADMIN") return "administrator";
    if(userCtx.role === "ROLE_MEDIC") return "djelatnik";
    if(userCtx.role === "ROLE_BOLESNIK") return "pacijent";
    if(userCtx.role === "ROLE_VODITELJ") return "voditelj ustanove";
  }

  if(userCtx.role==="NONE") return <div></div>;

  return (
    <div className={classes.greet}>

     Prijavljeni ste kao {getRole()} <b>{userCtx.name}</b>
      
    </div>
  );
}

export default Greeting;