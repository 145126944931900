import React from "react";
import classes from "./servicesList.module.css";
function ServiceItem(props){
    return(
        <li>
          <div className={classes.wrapper}  >
          <div className={classes.section}>
          <img className={classes.itemImage} src={props.image} alt={props.title} />
            <h2>{props.title} </h2>
            <p className={classes.description}>{props.description}</p>
          </div>
          </div>
        </li>
    );
}

export default ServiceItem;