import { Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import RegSuccessPage from './pages/RegSuccessPage';
import RegistrationBPage from './pages/RegistrationBPage';
import PatientsPage from './pages/PatientsPage';

import AddSuccessPage from './pages/AddSuccessPage';
import EvidencijaPage  from './pages/EvidencijaPage';
import MyServices from './pages/MyServices';

import Layout from './components/layout/Layout';
import AddService from './pages/AddServices';
import AddAppointment from './pages/AddAppointmentPage';
import BolesnikEvidencije from './pages/BolesnikEvidencije';
import Raspored from './pages/Raspored';



function App() {




  

  return (
    <Layout>
      <Routes>

        <Route path="/" element={<HomePage />} />
        <Route path="/myservices" element={<MyServices />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/service" element={<AddService />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/reg_success" element={<RegSuccessPage />} />
        <Route path="/registerb" element={<RegistrationBPage />} />
        <Route path="/patients" element={<PatientsPage />} />
        <Route path="/addtermin" element={<AddAppointment />} />
        <Route path="/add_success" element={<AddSuccessPage />} />
        <Route path="/evidencija" element={<EvidencijaPage />} />
        <Route path="/evidencije" element={<BolesnikEvidencije />} />
        <Route path="/raspored" element={<Raspored />} />
        


      </Routes>
    </Layout>
  );
}

export default App;
