import RegistrationForm from "../components/general/addServiceForm";

import apiPath from "../global/api";
import UserContext from "../store/user-context";
import {useContext} from 'react';


function AddService() {
 

    const userCtx = useContext(UserContext);

    function addServiceHandler(data){
      fetch(apiPath("add/usluga"), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }

        }).then((response)=>{
            return response.json();
            }).then(data=>{
                console.log(data);
                
            })
            ;
    }
    return (
      <section>
        
        {userCtx.role === 'ROLE_ADMIN' ? 
       
        <RegistrationForm onRegister = {addServiceHandler}/>
        : <h1> Neovlašten pristup! </h1>}

        
      </section>
    );
  }
  
  export default AddService;