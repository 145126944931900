import EvidencijaB from "./EvidencijaB";
import classes from "./servicesList.module.css";


function ListaEvidencija(props) {
  return (
    <ul className={classes.list}>
      {props.evidencije.map((evidencija) => (
        <EvidencijaB
          key={evidencija.id}
          id={evidencija.id}
          bolesnik_id = {evidencija.bolesnik_id}
          dan = {evidencija.dan}
          doktor_id = {evidencija.doktor_id}
          

        />
      ))}
    </ul>
  );
}

export default ListaEvidencija;