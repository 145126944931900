import LoginForm from "../components/general/loginForm";
import {useNavigate} from 'react-router-dom';
import AuthService from "../services/auth.service";
import { useState } from "react";
import LoginErrorPage from "./LoginErrorPage";

import {useContext} from 'react';
import UserContext from "../store/user-context";


function LoginPage() {

  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  const [loginError, setError] = useState(false);
  
    function loginHandler(data){
      AuthService.login(data.username, data.password).then(
        (resData) => {
            console.log(resData);

            
            userCtx.setRole(resData.roles[0], resData.ime, resData.id);
            

          navigate("/", { replace: true });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            setError(resMessage);
          console.log(resMessage);
        }
      );
    }
  return (
      <section>
        <LoginForm onLogin = {loginHandler}/>
        {loginError ? <LoginErrorPage message={loginError} /> : null}
      </section>
    );
  }
  
  export default LoginPage;