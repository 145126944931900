import React from "react";
import classes from "./servicesList.module.css";
import buttonclasses from "./registerForm.module.css"
import UserContext from "../../store/user-context";
import {useNavigate} from 'react-router-dom';
import apiPath from "../../global/api";
import{useContext} from "react";
import PatientServices from "./PatientServices";



function EvidentirajPacijenta(props){

    const userCtx = useContext(UserContext);

    const navigate = useNavigate();
    function EvidencijaHandler(data){
        
        fetch(apiPath("add/evidencija"), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }

        }).then((response)=>{
            return response.json();
            }).then(data=>{
              console.log(data);
              navigate("/evidencija", { replace: true });
               
            })
            ;}
   
        function padTo2Digits(num){
            return num.toString().padStart(2,'0');
        }
        function formatDate(date){
            return [
                date.getFullYear(),
                padTo2Digits(date.getMonth()+1),
                padTo2Digits(date.getDate())
            ].join('-');
        }
   

        function submitHandler(event){          // funkcija za obradu submit-a  
            event.preventDefault();           // sprijeci podrazumjevano ponasanje (GET)
            const datum= new Date();
            datum.setHours(0,0,0,0);
           
            
            
            const registerData = {
                
                dan:formatDate(datum),
               
                bolesnik_id: props.id,
                doktor_id: userCtx.id
    
              };
    
              EvidencijaHandler(registerData);

    }
    return(
        
        <li>
          <div className={classes.wrapper} >
          <div className={classes.section} >
          <h3>{props.firstname} {props.lastname} </h3>
            <p>MBO: {props.mbo}</p>
            <p>Dijagnoza: {props.diagnosis}</p>
            <p><b>Usluge:</b> <PatientServices id={props.id}/> </p>
            <p>Dodatno Zdravstveno: {props.dodatno}</p>
            <p>Kontakt: {props.email}, {props.phone}</p>
          <button   onClick ={submitHandler} className={buttonclasses.btn}>Evidentiraj</button>
          
          </div>
          
          </div>
          
        </li>
        
    );
}

export default EvidentirajPacijenta;