import React from "react";
import { useState, useEffect } from 'react';
import classes from "./servicesList.module.css";
import ServiceList from "./ServiceList"
import apiPath from "../../global/api";


function ServicesForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedServices, setLoadedServices] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      apiPath("usluge"),{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }

    }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const services = [];

        for (const key in data) {
          const service = {
            id: key,
            ...data[key]
          };
  
          services.push(service);
        }

        setIsLoading(false);
        setLoadedServices(services);
      });
  }, []);
   if (isLoading) {
    return (
      <section>
        <div className={classes.service}>
         <div className={classes.bg}/>
         <div className={classes.bgfadetopmoving}/>
         <div className={classes.bgfadebottom}/>

        <h1 > Zdravlje <b>nije sve</b>, ali bez zdravlja sve drugo je <b>ništa</b>.</h1>
        <p>Učitavanje usluga...</p>
        </div>
      </section>
    );
  } 
  return (
  
      <div className={classes.service}>
         <div className={classes.bg}/>
         <div className={classes.bgfadetopmoving}/>
         <div className={classes.bgfadebottom}/>

        <h1> Zdravlje <b>nije sve</b>, ali bez zdravlja sve drugo je <b>ništa</b>.</h1>

        <div className={classes.listContainer}>

        <ServiceList services={loadedServices} />

        </div>


        <h1 className={classes.covidTitle}><b>COVID-19</b> statistika za RH</h1>
        <iframe title="COVID" src="https://ourworldindata.org/grapher/covid-confirmed-cases-since-100th-case?country=Croatia" ></iframe>
          
       

       </div>
      
  
  );
}

export default ServicesForm;
