import RegistrationForm from "../components/general/registerForm";
import { useState, useContext } from "react";
import RegSuccessPage from "./RegSuccessPage";
import UserContext from "../store/user-context";
import React from "react";
import apiPath from "../global/api";

function RegistrationPage() {

    const userCtx = useContext(UserContext);

    

    const [isRegistered, setIsRegistered] = useState(false);

    function registrationHandler(data){

        fetch(apiPath("signup/d"), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }

        }).then((response)=>{
            return response.json();
            }).then(data=>{
                setIsRegistered(data.message);
            })
            ;
    }

    return (
      <section>
        {userCtx.role === 'ROLE_ADMIN' ? 
        <RegistrationForm onRegister = {registrationHandler}/>
        : <h1> Neovlašten pristup! </h1>}

        {isRegistered ? <RegSuccessPage message={isRegistered} /> : null}

      </section>
    );
  }
  
  export default RegistrationPage;
