import React from "react";
import classes from "./servicesList.module.css";

function RasporedTermina(props){
    return(
        <li>
          <div className={classes.wrapper} >
          <div className={classes.section}>
            <h3>{props.usluga} </h3>
            <p>Početak termina: {props.pocetak_termina}</p>
            <p>Kraj termina: {props.kraj_termina}</p>
            <p>Djelatnik: {props.ime_djelatnik} {props.prezime_djelatnik}</p>
           
          </div>
          </div>
        </li>
    );
}

export default RasporedTermina;