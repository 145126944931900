import { useContext, useRef } from 'react';
import React from 'react';
import classes from  "./registerForm.module.css";
import UserContext from '../../store/user-context';




function AddAppointmentForm(props) {
    const dateInputRef = useRef();

    const uslugaInputRef= useRef();

    const userCtx = useContext(UserContext);
    
    

    function submitHandler(event){          // funkcija za obradu submit-a  
        event.preventDefault();             // sprijeci podrazumjevano ponasanje (GET)

        const enteredDate= dateInputRef.current.value;
        const enteredUsluga = uslugaInputRef.current.value;

        const appointmentData = {
            pocetak_termina: enteredDate,
            usluga_id: enteredUsluga 
          };

          console.log();

          props.onRegister(appointmentData);       //funkcija koja definira sto ce se dogoditi s unesenim podacima se definira u pozivajucoj komponenti
    }

    return(
      <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.formBody}>
             
              <div className="pocetak_termina">
                  <label className={classes.label} for="pocetak_termina" ref={dateInputRef}>Početak termina: </label>
                  <input  required type="datetime-local" />
              </div>


                
              <div className="usluga_id">
                    <label className={classes.label} for="usluga_id">ID usluge: </label>
                    <input  required type="number" name="" id="usluga_id"  className="form__input" placeholder="" ref={uslugaInputRef}/>
                </div>

        
          </div>

          <div >
              <button type="submit" className={classes.btn}>Postavi</button>
          </div>

      </form>      
    )       
}
export default AddAppointmentForm;