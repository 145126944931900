import classes from "./servicesList.module.css";
import React from "react";
import { useState, useEffect } from 'react';
import{useContext} from 'react';
import UserContext from "../../store/user-context";
import apiPath from "../../global/api";
import PatientList from "./PatientList"
function PatientsForm(){
    const userCtx = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [loadedPatients, setLoadedPatients] = useState([]);
    console.log(userCtx.id);
    useEffect(() => {
      setIsLoading(true);
      fetch(
        apiPath("bolesnici"),{
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
  
      }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const patients = [];
  
          for (const key in data) {
            const patient = {
              id: key,
              ...data[key]
            };
            
            if(patient.moj_lijecnik === userCtx.id){
            patients.push(patient);
            }

          }
          setIsLoading(false);
          setLoadedPatients(patients);
        });
    }, [userCtx.id]);
if(isLoading){
    return(
    <div className={classes.listContainer}>
       <p>Dohvaćanje pacijenata...</p>
    </div>
);
}
    return(
    <div className={classes.listContainer}>
       <PatientList patients={loadedPatients} />
    </div>
);


}
export default PatientsForm;