import EvidencijaForm from "../components/general/EvidencijaForm";
import { useContext } from "react";
import UserContext from "../store/user-context";

import React from "react";
function EvidencijaPage() {

    const userCtx = useContext(UserContext);
    
      
  
     
  
      return (
        <section>
          {userCtx.role === 'ROLE_MEDIC' ? 
          <EvidencijaForm />
          : <h1> Neovlašten pristup! </h1>}
          
          
        </section>
      );
    }
    
    export default EvidencijaPage;
  

