import React from "react";
import {   useContext } from 'react';

import UserContext from "../../store/user-context";
import PatientServices from "./PatientServices";


function MyServicesForm() {


  const userCtx = useContext(UserContext);


  
  return (
  
      <div >
        

        

        

        <p><b>Odabrane usluge:</b> <PatientServices id={userCtx.id}/> </p>

        


        
       

       </div>
      
  
  );
}

export default MyServicesForm;
