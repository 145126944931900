import React from 'react';
import { useRef } from 'react';
import classes from  "./registerForm.module.css";
import {useNavigate } from "react-router-dom";

function RegistrationForm(props){

    const navigate = useNavigate();
    const nameInputRef = useRef();
    const durationInputRef = useRef();
    const imageUrlInputRef = useRef();
    const resourcesInputRef = useRef();
    const descriptionInputRef = useRef();
    function addServiceHandler(event){          
        event.preventDefault(); 
        const enteredName = nameInputRef.current.value;
        const enteredDuration = durationInputRef.current.value;
        const enteredUrl = imageUrlInputRef.current.value;
        const enteredResources = resourcesInputRef.current.value;
        const enteredDesc = descriptionInputRef.current.value;
        
        const serviceData = {
            naziv: enteredName,
            opis: enteredDesc,
            slika: enteredUrl,
            trajanje: enteredDuration,
            paralelno: enteredResources,
            
        };
        props.onRegister(serviceData);
        navigate("/");
    }


    return(
        <form className={classes.form} onSubmit={addServiceHandler}>
            <div className={classes.formBody}>
                <div className="naziv">
                    <label className="form__label" for="naziv">Naziv usluge: </label>
                    <input required className="form__input" type="text" id="naziv" placeholder="npr. Elektroterapija" ref={nameInputRef}/>
                </div>
            
                <div className="trajanje">
                    <label className={classes.label} for="trajanje">Trajanje usluge (min): </label>
                    <input  required type="number" name="" id="trajanje"  className="form__input" placeholder="npr. 90" ref={durationInputRef}/>
                </div>
                <div className="slika">
                    <label className={classes.label} for="slika">URL slike: </label>
                    <input  required type="text" name="" id="slika"  className="form__input" placeholder="www.example.jpg" ref={imageUrlInputRef}/>
                </div>
                <div className="paralelno">
                    <label className={classes.label} for="paralelno">Broj resursa: </label>
                    <input  required type="number" name="" id="paralelno"  className="form__input" placeholder="npr. 10" ref={resourcesInputRef}/>
                </div>
                <div className="opis">
                    <label className={classes.label} for="opis">Opis usluge: </label>
                    <textarea  required name="" id="opis"  className="form__input" placeholder="Opis usluge..." ref={descriptionInputRef}/>
                </div>
          
            </div>
  
            <div >
                <button type="submit" className={classes.btn}>Dodaj </button>
            </div>
  
        </form>      
      )  
}
export default RegistrationForm;