import EvidencijaBForm from "../components/general/EvidencijaBForm.js";
import { useContext } from "react";
import UserContext from "../store/user-context";

import React from "react";
function BolesnikEvidencije() {

    const userCtx = useContext(UserContext);
    
      
  
     
  
      return (
        <section>
          {userCtx.role === 'ROLE_BOLESNIK' ? 
          <EvidencijaBForm />
          : <h1> Neovlašten pristup! </h1>}
          
          
        </section>
      );
    }
    
    export default BolesnikEvidencije;