import {  useRef ,useState } from 'react';
import React from 'react';
import classes from  "./registerForm.module.css";
import { useNavigate } from 'react-router-dom';
import PatientServices from './PatientServices';

import ServiceDropDown from './ServiceDropDown';
  

function AddBolesnikUslugeForm(props) {

    const navigate = useNavigate();
    const uslugeInputRef= useRef();

    const [serviceSelected, setServiceSelected] = useState("");
    
    
    
    function handleClick(event){
        event.preventDefault();
        navigate("/", { replace: true });

    }

    function submitHandler(event){          // funkcija za obradu submit-a  
        event.preventDefault();           // sprijeci podrazumjevano ponasanje (GET)
       
        
       
        const enteredUsluge = uslugeInputRef.current.value;
        
        if(serviceSelected.includes(enteredUsluge)) return null;

        const registerData = {
            
            usluga: enteredUsluge ,
            bolesnik_id: props.id

          };

          console.log(registerData);

          if(serviceSelected === "") setServiceSelected(enteredUsluge);
        else setServiceSelected(serviceSelected + ", " + enteredUsluge);
          

          props.onRegister(registerData);       //funkcija koja definira sto ce se dogoditi s unesenim podacima se definira u pozivajucoj komponenti
       
        
        
    }
    return(
        <form className={classes.form} onSubmit={submitHandler} >
            <div className={classes.formBody}>
            
  
                <div>
              <label className={classes.label} for="mojeusluge">Odaberi uslugu:  <ServiceDropDown reference={uslugeInputRef}/> </label>             
              </div>



                
                </div>
  
            <div >
                <button type="submit" className={classes.btn} >Dodaj </button>
                <button   className={classes.btn} onClick= {handleClick}>Završi </button>
            </div>
            
            <div>
                   
                    {serviceSelected === "" ? null : <div> <br/> <b>Odabrane usluge:</b> </div> }
                    {serviceSelected}
                
            </div>

  
        </form> 
             
      )       
  }
  export default AddBolesnikUslugeForm;