import Patient from "./Patient";
import classes from "./servicesList.module.css";


function PatientList(props) {



  return (
    <ul className={classes.list}>
      {props.patients.map((patient) => (
        <Patient
          key={patient.id}
          id={patient.id}
          diagnosis = {patient.dijagnoza}
          dodatno = {patient.dodatno_zdravstveno}
          email = {patient.email}
          firstname={patient.ime}
          lastname = {patient.prezime}
          mbo = {patient.mbo}
          doctor = {patient.moj_lijecnik}
          
          phone = {patient.telefon}

        />
      ))}
    </ul>
  );
}

export default PatientList;