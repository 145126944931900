import AddBolesnikUslugeForm from "../components/general/AddBolesnikUslugeForm";
import { useContext } from "react";
import apiPath from "../global/api";
import UserContext from "../store/user-context";
import {useNavigate} from 'react-router-dom';
import React from "react";
function AddBolesnikUsluge(props) {

    const userCtx = useContext(UserContext);
    const navigate = useNavigate();
      
  
      function addUslugeHandler(data){
        
          fetch(apiPath("add/bolesnik-usluga"), {
              method: 'POST',
              body: JSON.stringify(data),
              headers: {
                  'Content-Type': 'application/json'
                  
              }
  
          }).then((response)=>{
              return response.json();
              }).then(data=>{
                console.log(data);
                //navigate("/add/bolesnik-usluga", { replace: true });
                 
              })
              ;
              
      }
  
      return (
        <section>
          {userCtx.role === 'ROLE_MEDIC' ? 
          <AddBolesnikUslugeForm onRegister = {addUslugeHandler} id={props.id}/>
          : <h1> Neovlašten pristup! </h1>}
          
          
        </section>
      );
    }
    
    export default AddBolesnikUsluge;
  
