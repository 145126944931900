import { useContext, useRef } from 'react';
import React from 'react';
import classes from  "./registerForm.module.css";
import LijecnikDropDown from './LijecnikDropDown';
import UserContext from '../../store/user-context';
//import ServiceDropDown from './ServiceDropDown';






function RegisterBForm(props) {

    const nameInputRef = useRef();          //reference na dijelove (input) forme
    const lastnameInputRef = useRef();
    const numberInputRef = useRef();
    const emailInputRef = useRef();
    const diagnoseInputRef = useRef();
    const mboInputRef= useRef();
    const dodatnozdravstvenoInputRef= useRef();
    const lijecnikInputRef= useRef();
    //const uslugeInputRef= useRef();

    const userCtx = useContext(UserContext);
    
    

    function submitHandler(event){          // funkcija za obradu submit-a  
        event.preventDefault();             // sprijeci podrazumjevano ponasanje (GET)

        const enteredName = nameInputRef.current.value;
        const enteredLastName = lastnameInputRef.current.value;
        const enteredNumber = numberInputRef.current.value;
        const enteredEmail = emailInputRef.current.value;
        const enteredDiagnose = diagnoseInputRef.current.value;
        const enteredMBO = mboInputRef.current.value;
        const enteredDodatnoZdravstveno = dodatnozdravstvenoInputRef.current.value;
        //const enteredLijecnik = lijecnikInputRef.current.value;
        //const enteredUsluge = uslugeInputRef.current.value;

        const registerData = {
            ime: enteredName,
            prezime: enteredLastName,
            telefon: enteredNumber,
            email: enteredEmail,
            dijagnoza: enteredDiagnose,
            mbo: enteredMBO,
            dodatno_zdravstveno: enteredDodatnoZdravstveno,
            moj_lijecnik: userCtx.id,
           
          };

          console.log(registerData);

          props.onRegister(registerData);       //funkcija koja definira sto ce se dogoditi s unesenim podacima se definira u pozivajucoj komponenti
    }

    return(
      <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.formBody}>
              <div className="username">
                  <label className="form__label" for="firstName">Ime: </label>
                  <input required className="form__input" type="text" id="firstName" placeholder="Ime" ref={nameInputRef}/>
              </div>
              <div className="lastname">
                  <label className={classes.label} for="lastName">Prezime: </label>
                  <input  required type="text" name="" id="lastName"  className="form__input" placeholder="Prezime" ref={lastnameInputRef}/>
              </div>
              <div className="number">
                  <label className={classes.label} for="number">Broj telefona: </label>
                  <input  required type="number" name="" id="number"  className="form__input"placeholder="npr. +385991234567" ref={numberInputRef}/>
              </div>
              <div className="email">
                  <label className={classes.label} for="email">Email: </label>
                  <input  required type="email" id="email"pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="form__input" placeholder="email@npr.com" ref={emailInputRef}/>
              </div>
              <div className="dijagnoza">
                  <label className={classes.label} for="dijagnoza">Dijagnoza: </label>
                  <input  required type="text" name="" id="dijagnoza"   className="form__input" placeholder="npr. Skolioza" ref={diagnoseInputRef}/>
              </div>
              <div className="mbo">
                  <label className={classes.label} for="mbo">MBO: </label>
                  <input  required type="number" name="" id="mbo" maxLength="9"  minLength ="9" className="form__input" placeholder="Matični Broj Osiguranika" ref={mboInputRef}/>
              </div>
              <div className="dodatnozdravstveno">
                  <label className={classes.label} for="dodatnozdravstevno">Dodatno zdravstveno: </label>
                  <select required name="" id="dodatnozdravstevno"  className="form__input" ref={dodatnozdravstvenoInputRef}>
                    <option value="Ne">Ne</option>
                     <option value="Da">Da</option>
                 </select>

              </div>

                <div>
                <label className={classes.label} for="mojlijecnik">Uputio liječnik:  <LijecnikDropDown reference={lijecnikInputRef}/> </label>
                </div>
              
              </div>

          <div >
              <button type="submit"  className={classes.btn}>Registriraj </button>
          </div>

      </form>      
    )       
}
export default RegisterBForm;