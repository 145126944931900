import classes from "./servicesList.module.css";
import React from "react";

import { useState, useEffect } from 'react';
import apiPath from "../../global/api";
import ListaPacijenataE from "./ListaPacijenataE"
function EvidencijaForm(){
    
    const [isLoading, setIsLoading] = useState(true);
    const [loadedPatients, setLoadedPatients] = useState([]);
    
    useEffect(() => {
      setIsLoading(true);
      fetch(
        apiPath("bolesnici"),{
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
  
      }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const patients = [];
  
          for (const key in data) {
            const patient = {
              id: key,
              ...data[key]
            };
            
            
            patients.push(patient);
            

          }
          setIsLoading(false);
          setLoadedPatients(patients);
        });
    },[] );
if(isLoading){
    return(
    <div className={classes.listContainer}>
       <p>Dohvaćanje pacijenata...</p>
    </div>
);
}
    return(
    <div className={classes.listContainer}>
       <ListaPacijenataE patients={loadedPatients} />
    </div>
);


}
export default EvidencijaForm;