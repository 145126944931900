
import { useContext } from "react";
import MyServicesForm from "../components/general/MyServicesForm";
import UserContext from "../store/user-context";


function MyServices() {
  const userCtx = useContext(UserContext);
    return (
      <section>
        {userCtx.role === 'ROLE_BOLESNIK' ? 
        <MyServicesForm/>
        : <h1> Neovlašten pristup! </h1>}
        
 
      </section>
    );
  }
  
  export default MyServices;