import React from "react";
import classes from "./servicesList.module.css";
import buttonclasses from "./registerForm.module.css"
import PatientServices from "./PatientServices";
function Patient(props){
    return(
        <li>
          <div className={classes.wrapper} >
          <div className={classes.section}>
            <h3>{props.firstname} {props.lastname} </h3>
            <p>MBO: {props.mbo}</p>
            <p>Dijagnoza: {props.diagnosis}</p>


            <p><b>Usluge:</b> <PatientServices id={props.id}/> </p>


            <p>Dodatno Zdravstveno: {props.dodatno}</p>
            <p>Kontakt: {props.email}, {props.phone}</p>
          <button onClick={()=> window.location.replace("/addtermin")} className={buttonclasses.btn}>Postavi termin</button>
          <button className={buttonclasses.btn}>Završi tretman</button>
          </div>
          </div>
        </li>
    );
}

export default Patient;