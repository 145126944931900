import {createContext, useState, useEffect} from 'react';


const UserContext = createContext({
    role: 'NONE',
    name: "",
    id:"",
    setRole: (role) => {}
});

export function UserContextProvider(props){
    const [userRole, setUserRole] = useState([]);
    const [userName, setUserName] = useState([]);
    const [userId, setUserId] = useState([]);



    useEffect(()=>{
        if(localStorage.name){
            setUserRole(localStorage.role);
            setUserName(localStorage.name);
            setUserId(localStorage.id);
        }else{
            setUserRole("NONE");
            setUserName("");
            setUserId("");
        }
        
        
    },[]);

    function setRoleHandler(role,name,id){
        setUserRole(role);
        setUserName(name);
        setUserId(id);
        localStorage.name=name;
        localStorage.role=role;
        localStorage.id=id;
        
    }


    

    const context = {
        role: userRole,
        name: userName,
        id: userId,
        setRole: setRoleHandler
      };
    
      return (
        <UserContext.Provider value={context}>
          {props.children}
        </UserContext.Provider>
      );
}

export default UserContext;