import classes from "./servicesList.module.css";
import React from "react";
import { useContext } from "react";

import UserContext from "../../store/user-context";
import { useState, useEffect } from 'react';
import apiPath from "../../global/api";
import ListaEvidencija from "./ListaEvidencija"
function EvidencijaBForm(){
    
    const [isLoading, setIsLoading] = useState(true);
    const [loadedEvidencije, setLoadedEvidencije] = useState([]);
    const userCtx = useContext(UserContext);
    useEffect(() => {
      setIsLoading(true);
      
      
      fetch(
        apiPath("evidencije/"+userCtx.id ),{
          method: 'GET',
    
          headers: {
              'Content-Type': 'application/json'
          }
  
      }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const evidencije = [];
  
          for (const key in data) {
            const evidencija = {
              id: key,
              ...data[key]
            };
           
            console.log(evidencija);
            evidencije.push(evidencija);
            
            
          }
          setIsLoading(false);
          setLoadedEvidencije(evidencije);
        });
    },[userCtx.id] );
if(isLoading){
    return(
    <div className={classes.listContainer}>
       <p>Dohvaćanje evidencija...</p>
    </div>
);
}
    return(
    <div className={classes.listContainer}>
       <ListaEvidencija evidencije={loadedEvidencije} />
    </div>
);


}
export default EvidencijaBForm;