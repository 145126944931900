
import ServicesForm from "../components/general/ServicesForm";


function HomePage() {
    return (
      <section>
        
        <ServicesForm/>
 
      </section>
    );
  }
  
  export default HomePage;