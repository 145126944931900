import AddAppointmentForm from '../components/general/addAppointmentForm';
import {useNavigate} from 'react-router-dom';
import apiPath from "../global/api";
import UserContext from "../store/user-context";
import {useContext} from 'react';



function AddAppointment() {
  const navigate = useNavigate();

    const userCtx = useContext(UserContext);

    function addAppointmentHandler(data){
      fetch(apiPath("add/termin"), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }

        }).then((response)=>{
            return response.json();
            }).then(data=>{
                console.log(data);
                navigate("/patients", { replace: true });
            })
            ;
    }
    return (
      <section>
        
        {userCtx.role === 'ROLE_MEDIC' ? 
       
        <AddAppointmentForm onRegister = {addAppointmentHandler}/>
        : <h1> Neovlašten pristup! </h1>}

        
      </section>
    );
  }
  
  export default AddAppointment;