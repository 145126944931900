import React from 'react';
import classes from  "./ShowSuccess.module.css";
import CardWrapper from '../UI/cardWrapper';
import {useNavigate} from 'react-router-dom';

function ShowSuccess(props) {

    const navigate = useNavigate();

    function handleContinue(){
        navigate("/", { replace: true });
    }

    return(
      <div className = {classes.container}  >
        <div className = {classes.content}>
        <CardWrapper>
            <div className = {classes.message}>
                {props.message} 
            </div>
            <button className={classes.btn} onClick={handleContinue}> NASTAVI</button>
        </CardWrapper>
        </div>

      </div>
    )       
}
export default ShowSuccess;