import LoginError from "../components/general/LoginError";

function LoginErrorPage(props) {
    return (
      
        < LoginError message = {props.message} />
     
    );
  }
  
export default LoginErrorPage;