import PatientsForm from "../components/general/patientsForm";

function PatientsPage() {
  
    return (
      <section>
        
        <PatientsForm/>
 
      </section>
    );
  }
  
  export default PatientsPage;