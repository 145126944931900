import RegistrationBForm from "../components/general/registerBForm";
import { useState, useContext } from "react";
import AddSuccessPage from "./AddSuccessPage";
import apiPath from "../global/api";
import UserContext from "../store/user-context";
import React from "react";

function RegistrationBPage() {

  const userCtx = useContext(UserContext);
  
    const [isRegistered, setIsRegistered] = useState(false);
    const [id, setId] = useState("false");

    

    function registrationHandler(data){
      
        fetch(apiPath("signup/b"), {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }

        }).then((response)=>{
            return response.json();
            }).then(data=>{

                setIsRegistered(data.message);
                setId(data.id);
                
                
            })
            ;
            
    }

    return (
      <section>
        {userCtx.role === 'ROLE_MEDIC' ? 
        <RegistrationBForm onRegister = {registrationHandler}/>
        : <h1> Neovlašten pristup! </h1>}
        
        {isRegistered ? <AddSuccessPage message={isRegistered} id = {id}/> : null}
      </section>
    );
  }
  
  export default RegistrationBPage;
