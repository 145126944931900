import axios from "axios";
import apiPath from "../global/api";



const API_URL = apiPath("");


class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password
      })
      .then(response => {

        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  addService(name, description, url, duration, rescources){
    return axios
      .post(API_URL + "add/usluga", {
        name,
        description,
        url,
        duration, 
        rescources
        
    }).then(response => {
      return response.data;
    }
    ); }




}

export default new AuthService();
