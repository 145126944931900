import ServiceItem from './ServiceItem';
import classes from "./servicesList.module.css";

function ServiceList(props) {
  return (
    <ul className={classes.list}>
      {props.services.map((service) => (
        <ServiceItem
          key={service.id}
          id={service.id}
          image={service.slika}
          title={service.naziv}
          description={service.opis}
        />
      ))}
    </ul>
  );
}

export default ServiceList;