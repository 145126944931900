import ShowSuccess from "../components/general/ShowSuccess";

function RegSuccessPage(props) {
    return (
      
        < ShowSuccess message = {props.message} />
     
    );
  }
  
export default RegSuccessPage;