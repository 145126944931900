import classes from "./servicesList.module.css";
import React from "react";
import { useContext } from "react";

import UserContext from "../../store/user-context";
import { useState, useEffect } from 'react';
import apiPath from "../../global/api";
import ListaRasporeda from "./ListaRasporeda"
function RasporedForm(){
    
    const [isLoading, setIsLoading] = useState(true);
    const [loadedRaspored, setLoadedRaspored] = useState([]);
    const userCtx = useContext(UserContext);
    
    useEffect(() => {
      setIsLoading(true);
      
      fetch(
        apiPath("termini/" + userCtx.id  ),{
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
  
      }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const termini = [];
  
          for (const key in data) {
            const termin = {
              id: key,
              ...data[key]
            };
           
            
            termini.push(termin);
            
            
            
          }
          setIsLoading(false);
          setLoadedRaspored(termini);
        });
    },[userCtx.id ] );
if(isLoading){
    return(
    <div className={classes.listContainer}>
       <p>Dohvaćanje rasporeda...</p>
    </div>
);
}
    return(
    <div className={classes.listContainer}>
       <ListaRasporeda termini={loadedRaspored} />
    </div>
);


}
export default RasporedForm;