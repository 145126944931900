import classes from "./servicesList.module.css";
import React from "react";

import { useState, useEffect } from 'react';
import apiPath from "../../global/api";
import PatientServicesList from "./PatientServicesList";


function PatientServices(props){
    
    const [isLoading, setIsLoading] = useState(true);
    const [loadedServices, setLoadedServices] = useState([]);
    
    useEffect(() => {
      setIsLoading(true);
      fetch(
        apiPath("bolesnik-usluge/"+ props.id ),{
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
  
      }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const services = [];
  
          for (const key in data) {
            const service = {
              id: key,
              ...data[key]
            };
            
            
            services.push(service);
            

          }
          console.log(services);
          setIsLoading(false);
          setLoadedServices(services);
        });
    },[props.id] );
if(isLoading){
    return(
    <div className={classes.listContainer}>
       <p>Dohvaćanje usluga...</p>
    </div>
);
}
    return(
    <div className={classes.listContainer}>
       <PatientServicesList services={loadedServices} />
    </div>
);


}
export default PatientServices;