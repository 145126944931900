import { useRef } from 'react';
import React from 'react';
import classes from  "./registerForm.module.css";

function RegistrationForm(props) {

    const nameInputRef = useRef();          //reference na dijelove (input) forme
    const lastnameInputRef = useRef();
    const fieldInputRef = useRef();
    const specialisationInputRef = useRef();
    const emailInputRef = useRef();
    const numberInputRef = useRef();

    function submitHandler(event){          // funkcija za obradu submit-a  
        event.preventDefault();             // sprijeci podrazumjevano ponasanje (GET)

        const enteredName = nameInputRef.current.value;
        const enteredLastName = lastnameInputRef.current.value;
        const enteredField = fieldInputRef.current.value;
        const enteredSpecialisation = specialisationInputRef.current.value;
        const enteredEmail = emailInputRef.current.value;
        const enteredNumber = numberInputRef.current.value;

        const registerData = {
            ime: enteredName,
            prezime: enteredLastName,
            strucna_sprema: enteredField,
            specijalizacija: enteredSpecialisation,
            email: enteredEmail,
            telefon: enteredNumber 
          };

          props.onRegister(registerData);       //funkcija koja definira sto ce se dogoditi s unesenim podacima se definira u pozivajucoj komponenti
    }

    return(
      <form className={classes.form} onSubmit={submitHandler}>
          <div className={classes.formBody}>
              <div className="username">
                  <label className="form__label" for="firstName">Ime: </label>
                  <input required className="form__input" type="text" id="firstName" placeholder="Ime" ref={nameInputRef}/>
              </div>
              <div className="lastname">
                  <label className={classes.label} for="lastName">Prezime: </label>
                  <input  required type="text" name="" id="lastName"  className="form__input" placeholder="Prezime" ref={lastnameInputRef}/>
              </div>
              <div className="strucnasprema">
                  <label className={classes.label} for="strucnasprema">Stručna sprema: </label>
                  <input  required type="text" name="" id="strucnasprema"  className="form__input" placeholder="npr. Doktorski studij" ref={fieldInputRef}/>
              </div>
              <div className="specijalizacija">
                  <label className={classes.label} for="specijalizacija">Specijalizacija: </label>
                  <input  required type="text" name="" id="specijalizacija"  className="form__input" placeholder="npr. Kardiologija" ref={specialisationInputRef}/>
              </div>
              <div className="email">
                  <label className={classes.label} for="email">Email: </label>
                  <input  required type="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="form__input" placeholder="email@npr.com" ref={emailInputRef}/>
              </div>
              <div className="number">
                  <label className={classes.label} for="number">Broj telefona: </label>
                  <input  required type="number" name="" id="number"  className="form__input"placeholder="npr. +385991234567" ref={numberInputRef}/>
              </div>
        
          </div>

          <div >
              <button type="submit" className={classes.btn}>Registriraj </button>
          </div>

      </form>      
    )       
}
export default RegistrationForm;