
import classes from './cardWrapper.module.css';

function CardWrapper(props) {
  return (
    <div className={classes.bg}>
    <div className={classes.card}>
      <div className={classes.main}>{props.children}</div>
    </div>
    </div>
  );
}

export default CardWrapper;