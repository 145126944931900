import { useRef, useState } from 'react';
import React from 'react';
import classes from  "./registerForm.module.css";

function RegistrationForm(props) {
    const emailInputRef = useRef();
    const passInputRef = useRef();

    const[isEmail, setEmail] = useState(false);



    function loginHandler(event){          
        event.preventDefault(); 
        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passInputRef.current.value;

        const loginData = {
            username: enteredEmail,
            password: enteredPassword 
        };
        props.onLogin(loginData);
    }

    return(
      <form className={classes.form} onSubmit={loginHandler}>
          <div className={classes.formBody}>

             {isEmail ?   
              <div className="email">
                  <label className={classes.label} for="email">Email: </label>                 
                  <input  type="email" id="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" className="form__input" placeholder="email@npr.com" ref={emailInputRef} required/>
              </div>
                :
              <div className="email">
                  <label className={classes.label} for="email">MBO: </label>                 
                  <input  type="number" id="email" className="form__input" placeholder="123456789" ref={emailInputRef} required/>
              </div>
              } 


              <div className="password">
                  <label className={classes.label} for="number">Lozinka: </label>
                  <input  type="password" name="" id="password"  className="form__input"placeholder="lozinka" ref={passInputRef} required/>
              </div>

              
              {isEmail ? <button type='Button' className={classes.btn}  onClick={()=>setEmail(false)} > Prijavi se kao pacijent </button>
                : <button type='Button' className={classes.btn}  onClick={()=>setEmail(true)} > Prijavi se kao djelatnik </button>
                }
               
              
              
        
          </div>
          <div >
              <button type="submit" className={classes.btn}>Nastavi </button>
          </div>
      </form>      
    )        
}
export default RegistrationForm;