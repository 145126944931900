import React from 'react';
import classes from  "./ShowSuccess.module.css";
import CardWrapper from '../UI/cardWrapper';
import { useNavigate } from "react-router-dom";
import {useState} from "react";
import AddBolesnikUsluge from '../../pages/AddBolesnikUsluge';

function ShowSuccessAdded(props) {

    

    const [next, setNext] = useState(false);
    const navigate = useNavigate();

    function handleContinue(){
        setNext(true);
    }

    function handleReturn(){
        navigate("/");
    }

    return(
      <div className = {classes.container}  >
        <div className = {classes.content}>
          {next ? 
          <CardWrapper>
          <AddBolesnikUsluge id= {props.id}/>
          </CardWrapper>
          :
          <CardWrapper>
          <div className = {classes.message}>
              {props.message} 
          </div>

          {props.message.includes("Error") ?

          <button className={classes.btn} onClick={handleReturn}> POVRATAK </button>
          :
          <button className={classes.btn} onClick={handleContinue}> NASTAVI</button>

          
          }
          
         
      </CardWrapper>
        }

        </div>

      </div>
    )       
}
export default ShowSuccessAdded;