import { Link } from "react-router-dom";

import classes from "./Navigation.module.css";

import{useContext} from 'react';
import UserContext from "../../store/user-context";

function MainNavigation() {

  const userCtx = useContext(UserContext);
 


  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <div className={classes.logo}>
          {" "}
          <img
            className={classes.logoImage}
            src={require("./logo.png")}
            alt=""
          />{" "}
          ZNANJEM DO ZDRAVLJA{" "}
          

        </div>

        <div className={classes.linksContainer}>
          
      
          <Link to="/" className={classes.linkElement}>
            NASLOVNA 
          </Link>

          {userCtx.role === 'NONE' ? 
          <Link to="/login" className={classes.linkElement}>
            PRIJAVA
          </Link>
          : null}

          {userCtx.role === 'ROLE_MEDIC' ? 

          <Link to="/registerb" className={classes.linkElement}>
            REGISTRACIJA PACIJENTA
          </Link>
          
          : null}

          {userCtx.role === 'ROLE_MEDIC' ? 

          <Link to="/patients" className={classes.linkElement}>
           MOJI PACIJENTI 
          </Link>

          : null} 

          
{userCtx.role === 'ROLE_MEDIC' ? 

<Link to="/evidencija" className={classes.linkElement}>
 EVIDENCIJA
</Link>

: null} 

          {userCtx.role === 'ROLE_ADMIN' ? 

          <Link to="/register" className={classes.linkElement}>
             REGISTRACIJA DJELATNIKA 
          </Link>

          : null} 


          {userCtx.role === 'ROLE_ADMIN' ? 

          <Link to="/service" className={classes.linkElement}>
            DODAJ USLUGU
          </Link>

          : null} 

{userCtx.role === 'ROLE_BOLESNIK' ? 

<Link to="/myservices" className={classes.linkElement}>
  MOJE USLUGE
</Link>

: null} 

{userCtx.role === 'ROLE_BOLESNIK' ? 

<Link to="/raspored" className={classes.linkElement}>
  RASPORED
</Link>

: null} 
{userCtx.role === 'ROLE_BOLESNIK' ? 

<Link to="/evidencije" className={classes.linkElement}>
  EVIDENCIJE
</Link>

: null} 



          {userCtx.role !== 'NONE' ? 
          
          <Link to="/" onClick={()=>{userCtx.setRole('NONE',"","")}} className={classes.linkElement}>ODJAVA</Link> 
          
          : null}

          

         
        </div>
      </div>
    </div>
  );
}

export default MainNavigation;
