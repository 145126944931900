import React from 'react';
import classes from  "./ShowSuccess.module.css";
import CardWrapper from '../UI/cardWrapper';


function LoginError(props) {


    function handleContinue(){
        window.location.reload(false);
    }

    return(
      <div className = {classes.container}  >
        <div className = {classes.content}>
        <CardWrapper>
            <div className = {classes.message}>
                Neispravni e-mail ili lozinka!
            </div>
            <button className={classes.btn} onClick={handleContinue}> POKUŠAJ PONOVO</button>
        </CardWrapper>
        </div>

      </div>
    )       
}
export default LoginError;